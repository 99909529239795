.menu {
    display: flex;
    z-index: 2;
    flex-direction: column;
    align-items: center;
}

.menuContainer {
    width: 100%;
    display: flex;
    z-index: 2;
    justify-content: center;
}

.menuImgContainer {
    display: flex;
    margin-top: -10px;
  }
  
  .menuImgContainer img {
    height: 1.5rem;
    width: auto;
    padding-left: 0.5rem;;
    padding-right: 0.5rem;;
  }

.menu p {
    margin: 0;
    padding-bottom: 1rem;
    font-size: 2rem;
}

.menu p.last {
    padding-bottom: 0;
}

.contentPane {
    width: 100%;
}

.contentParagraph {
    line-height: 1.3;
    font-size: 0.9rem;
    text-align: center;
}

.contentHeader_container {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.contentHeader_container img {
    width: 2rem;
    margin-right: 2rem;
}

.contentHeading {
    writing-mode: vertical-lr;
    margin: 2rem;
    font-size: 2rem;
}


@media only screen and (min-width: 992px) {
    .menuContainer {
        justify-content: flex-end;
    }

    .menu {
        align-items: end;
    }

    .contentParagraph {
        line-height: 1.5;
        font-size: 2rem;
        text-align: center;
    }


}