@font-face {
  font-family: 'Metropolis-Light';
  src:url('./fonts/Metropolis-Light.woff') format('woff'),
      url('./fonts/Metropolis-Light.eot'),
      url('./fonts/Metropolis-Light.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: 'Metropolis-Light';
  color: white;
  font-size: 4rem;
}

.App {
  height: 100%;
}

#root {
  height: 100vh;
}

.welcome {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  height: 100%;
  padding: 0 2rem 0 2rem;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.videoContainer video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.welcome img {
  width: 50%;
  max-width: 300px;
}

.headerFooterContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img.headerImg {
  width: 4.5rem;;
  height: auto;
  align-self: flex-start;
}

img.menuImg {
  align-self: flex-start;
  width: 2rem;
  height: auto;
}

div.footer {
  position: absolute;
  bottom: 0;
  left: 0;
}

div.header {
  position: absolute;
  top: 0;
  left: 0;
}

div.headerFooterContainer > img {
  margin: 2rem;
}

.pointer {
  cursor: pointer;
}

.displayNone {
  display: none;
}